import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet';
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from '../components/Layout'
import CompactPostList from '../components/CompactPostList'

const CookingWithSteve = ({ data }) => {
  const { meat } = data
  const { title: siteTitle } = data.site.siteMetadata
  const { fluid: pageHeroImg } = data.heroImg.localFile.childImageSharp
  const { fluid: grillImg } = data.grillImg.childImageSharp

  const Hero = styled.div`
    position: relative;
    height: 0;
    top: 50px;
    padding-bottom: 60%;
    margin-bottom: 50px;

    @media(min-width: 600px) {
      padding-bottom: 30%;
    }

    &:before {
      content: '';
      width: 100%;
      height: 150px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0,0,0,0.7), transparent);
    }
  `

  const Heading = styled.h1`
    color: #fff !important;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    @media(min-width: 850px) {
      top: 80%;
      width: auto;
    }
  `

  const Image = styled(Img)`
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  `

  return (
    <Layout>
      <Helmet title={`Cooking with Steve | ${siteTitle}`} />
      <Hero>
        <Image fluid={pageHeroImg} style={{position: `absolute`}} />
        <Heading className="title is-size-1">Cooking with Steve</Heading>
      </Hero>
      <CompactPostList posts={meat.edges} grillImg={grillImg} title="Recent cooking tips, tricks, and recipes" />
    </Layout>
  )
}
export default CookingWithSteve

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    meat: allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "meat"}}}}) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    smoked: allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "smoked-foods"}}}}) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    recipes: allWordpressPost(filter: {categories: {elemMatch: {slug: {eq: "recipes"}}}}) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    heroImg: wordpressWpMedia(slug: {eq: "sliced-brisket"}) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    grillImg: file(name: {eq: "grill"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
